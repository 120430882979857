<template>
  <FormSection
    :icon="isActive ? 'eva-archive' : 'eva-archive-outline'"
    :is-active="isActive"
    style="width: 100vw"
    v-on="$listeners"
  >
    <template #title>Folder Details</template>

    <template #description>
      Enter the desired name of the folder you'd like to create as a root folder
      to organize your documents.
    </template>

    <!-- name -->

    <ValidationProvider
      v-slot="{ errors }"
      name="name"
      :rules="{ required: true }"
    >
      <TextField
        :value="name"
        label="name"
        is-mandatory
        :is-readonly="mode === 'SECURE'"
        :error="errors[0]"
        class="q-mb-lg"
        @input="updateName"
      />
    </ValidationProvider>

    <!-- ... -->
  </FormSection>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import FormSection from "@/components/common/item-builder/FormSection.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";

export default {
  name: "RepositoryDetails",

  components: {
    FormSection,
    ValidationProvider,
    TextField,
  },

  props: {
    name: {
      type: String,
      required: true,
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    mode: {
      type: String,
      default: "",
    },
  },

  methods: {
    updateName(name) {
      this.$emit("update:name", name);
    },

    updateDescription(description) {
      this.$emit("update:description", description);
    },
  },
};
</script>

<style lang="scss" scoped></style>
