<template>
  <FormSection
    :icon="isActive ? 'mdi-account' : 'mdi-account-outline'"
    :is-active="isActive"
    class="assignRetention"
    v-on="$listeners"
  >
    <template #title>Apply Retention</template>

    <RetentionSettings
      v-model="retentionSettings"
      :repository-id="repositoryId"
      :mode="modes"
      :retention-security="editRetention"
      :columns="fields"
      @refresh="handleRefresh"
      @reset="reset"
    />

    <!-- fields -->

    <div id="item-list">
      <div class="row">
        <div class="col-12 q-mb-md">
          <div class="row" style="float: right">
            <ExpandableSearch class="q-mr-sm col-auto" @search="searchRow" />

            <BaseActionButton
              v-tooltip.top="'refresh'"
              is-flat
              no-border
              icon="eva-refresh"
              class="col-auto"
              @click="handleRefresh"
            />

            <BaseButton
              icon="eva-plus"
              label="Add Retentions"
              class="q-ml-md col-auto"
              @click="add()"
            />
          </div>
        </div>

        <template v-if="retentionList.length">
          <div class="col-12">
            <BaseScrollbar class="q-pb-sm" style="max-height: 320px">
              <table>
                <thead>
                  <tr>
                    <th class="action"></th>
                    <th>Rule Name</th>
                    <th>Field</th>
                    <th>Action</th>
                    <th class="action"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="field in retentionList" :key="field.id">
                    <td>
                      <BaseActionButton
                        v-tooltip:secondary.left="'edit rule'"
                        is-flat
                        color="secondary"
                        icon="eva-edit-outline"
                        no-border
                        @click="edit(field.id)"
                      />
                    </td>
                    <td>{{ field.name }}</td>
                    <td>{{ getField(field.fieldId) }}</td>
                    <td>{{ field.action }}</td>
                    <td>
                      <BaseActionButton
                        v-tooltip:red.right="'delete rule'"
                        is-flat
                        color="red"
                        icon="eva-trash-2-outline"
                        no-border
                        @click="deleteRetention(field.id)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </BaseScrollbar>
          </div>
        </template>

        <template v-else>
          <div class="q-ma-xl text-bold">
            No retentions were found to protect your document. Please add a
            retention to ensure the security of your content.
          </div>
        </template>
      </div>
    </div>

    <!-- ... -->
  </FormSection>
</template>

<script>
import FormSection from "@/components/common/item-builder/FormSection.vue";
import RetentionSettings from "./components/Retention.vue";
import ExpandableSearch from "@/components/common/ExpandableSearch.vue";

import { repository } from "@/api/factory.js";
export default {
  name: "Retentions",

  components: {
    FormSection,
    RetentionSettings,
    ExpandableSearch,
  },

  props: {
    repositoryId: {
      type: String,
      default: "",
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    mode: {
      type: String,
      default: "NEW",
    },

    fields: {
      type: Array,
      required: true,
    },

    retentions: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      retentionSettings: false,
      retentionList: [],
      editRetention: {},
      modes: "",
      retentionListData: [],
    };
  },

  watch: {
    retentions: {
      immediate: true,
      deep: true,
      handler() {
        this.retentionList = [];
        if (this.retentions.length) {
          this.retentionList = this.retentions;
        }
      },
    },
  },

  mounted() {},

  methods: {
    handleRefresh(id) {
      this.$emit("refresh", id);
    },

    reset() {
      this.editRetention = {};
    },

    getField(fieldId) {
      if (fieldId === 0) {
        return "Uploaded Date";
      }
      let field = this.fields.find((field) => field.id === fieldId);
      if (field) {
        return field.name;
      }
      return "";
    },

    add() {
      this.editRetention = {};
      this.retentionSettings = true;
      this.modes = "ADD";
    },

    searchRow(search) {
      this.retentionListData = this.retentions;
      if (search) {
        this.retentionList = this.retentionListData.filter((item) => {
          return item.name.toLowerCase().includes(search.toLowerCase());
        });
      } else {
        this.retentionList = this.retentionListData;
      }
    },

    edit(id) {
      this.getRetentionById(id);
    },

    async deleteRetention(id) {
      let payload = { isDeleted: true };
      const { error } = await repository.updateRetention(id, payload);

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.$alert.success("Retention deleted succesfully");
      this.handleRefresh(this.repositoryId);
    },

    async getRetentionById(id) {
      const { error, payload } = await repository.getRetention(id);

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.editRetention = payload;
      this.retentionSettings = true;
      this.modes = "EDIT";
    },
  },
};
</script>

<style lang="scss" scoped>
#item-list {
  table {
    table-layout: fixed;
    max-width: 100%;
    min-width: calc(100% - 1px);
    border-collapse: collapse;
    white-space: nowrap;

    thead {
      tr {
        height: 36px;

        th {
          @extend .text-xs;
          padding: 8px 8px 16px;
          text-align: left;
          font-weight: normal;
          &.action {
            width: 30px;
          }
        }
      }
    }

    tbody {
      tr {
        border-top: 1px solid var(--divider-color);

        td {
          padding: 8px;
          height: 52px;
          text-align: left;
        }
      }
    }
  }
}
</style>

<style lang="scss">
#form-section.assignRetention {
  #form {
    width: 40vw !important;
  }
}
</style>
