<template>
  <Sheet
    :value="value"
    width="calc(100vw - 220px)"
    has-footer
    @input="closeSheet"
  >
    <!-- title -->

    <template #title>{{ name }} group security</template>

    <!-- ... -->

    <template #default>
      <div class="q-pa-md">
        <!-- group -->

        <GroupTable
          :list="groupList"
          :add-field.sync="security"
          :edit-field.sync="editField"
          :action="action"
          :text="text"
        />

        <!-- ... -->
      </div>
    </template>

    <!-- footer -->

    <template #footer>
      <BaseButton
        is-flat
        label="cancel"
        class="q-mr-sm"
        @click="closeSheet(false)"
      />

      <BaseButton
        :label="label"
        @click="mode === 'ADD' ? addField() : saveField()"
      />
    </template>

    <!-- ... -->
  </Sheet>
</template>

<script>
import Sheet from "@/components/common/popup/Sheet.vue";
import GroupTable from "./groupTable/GroupTable.vue";

import { repository } from "@/api/factory.js";

export default {
  name: "GroupSheet",

  components: {
    Sheet,
    GroupTable,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    editField: {
      type: Object,
      default: () => {},
    },

    mode: {
      type: String,
      required: true,
    },

    repositoryId: {
      type: String,
      default: "",
    },

    group: {
      type: Array,
      default: () => [],
    },

    groups: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      name: "",
      security: [],
      groupList: [],
      action: false,
      text: "",
    };
  },

  computed: {
    label() {
      if (this.mode === "ADD") {
        return "Add";
      } else return "Save";
    },
  },

  watch: {
    group: {
      immediate: true,
      deep: true,
      handler() {
        this.filterGroups();
      },
    },

    editField: {
      immediate: true,
      deep: true,
      handler() {
        this.filterGroups();
      },
    },

    mode: {
      immediate: true,
      handler() {
        if (this.mode === "ADD") {
          this.name = "Add";
        } else if (this.mode === "EDIT") {
          this.name = "Edit";
        }
      },
    },
  },

  mounted() {},

  methods: {
    reset() {
      this.security = [];
      this.action = false;
      this.text = "";
    },

    closeSheet() {
      this.$emit("input", false);
      this.$emit("reset");
      this.reset();
    },

    filterGroups() {
      if (Object.keys(this.editField).length !== 0) {
        const groupId = this.editField.groupId;

        const matchedGroup = this.groups.filter(
          (group) => groupId === group.value
        );

        this.groupList =
          matchedGroup &&
          matchedGroup.map((group) => ({
            id: this.$nano.id(),
            label: group.label,
            value: group.value,
          }));
      } else if (this.group.length) {
        const groupIds = this.group.map((g) => g.groupId);

        // Filter out payload items that match the 'group' array by ID
        const unmatchedGroups = this.groups.filter(
          (group) => !groupIds.includes(group.id)
        );

        this.groupList =
          unmatchedGroups &&
          unmatchedGroups.map((group) => ({
            id: this.$nano.id(),
            label: group.label,
            value: group.value,
          }));
      } else {
        this.groupList = this.groups;
      }
    },

    async addField() {
      const groupIds = this.security.map((item) => item.groupId);

      const options = this.security.map((item) => (item.options.view ? 0 : 1));

      if (groupIds.includes(0) && options.includes(1)) {
        this.action = true;
        this.text =
          "Don't leave GROUP colum blank and don't remove view from OPTIONS";
      } else if (groupIds.includes(0)) {
        this.action = true;
        this.text = "Select any of the group, don't leave GROUP column empty";
      } else if (options.includes(1)) {
        this.btn = true;
        this.text = "Don't remove view from OPTIONS column";
      } else {
        const updatedSecurity = this.security.map((item) => {
          //       // Create a copy of the item without the accessibilities property and id
          const newItem = { ...item };
          delete newItem.id;
          delete newItem.accessibilities;
          return newItem;
        });
        const payload = {
          repositoryId: this.repositoryId,
          userOptions: [],
          groupOptions: updatedSecurity,
        };

        const { error } = await repository.insertRepositorySecurity(payload);

        if (error) {
          this.$alert.error(error);
          return;
        }

        this.closeSheet();
        this.$alert.success("New folder security added succesfully");
        this.$emit("refresh", this.repositoryId);
      }
    },

    async saveField() {
      const groupIds = this.security.map((item) => item.groupId);

      const options = this.security.map((item) => (item.options.view ? 0 : 1));

      if (groupIds.includes(0) && options.includes(1)) {
        this.action = true;
        this.text =
          "Don't leave GROUP colum blank and don't remove view from OPTIONS";
      } else if (groupIds.includes(0)) {
        this.action = true;
        this.text = "Select any of the group, don't leave GROUP column empty";
      } else if (options.includes(1)) {
        this.btn = true;
        this.text = "Don't remove view from OPTIONS column";
      } else {
        const updatedSecurity = this.security.map((item) => {
          //       // Create a copy of the item without the accessibilities property and id
          const newItem = { ...item };
          delete newItem.accessibilities;
          return newItem;
        });
        const id = this.repositoryId;
        const payload = {
          repositoryId: this.repositoryId,
          userOptions: [],
          groupOptions: updatedSecurity,
        };

        const { error } = await repository.updateRepositorySecurity(
          id,
          payload
        );

        if (error) {
          this.$alert.error(error);
          return;
        }

        this.closeSheet();
        this.$alert.success("Folder security updated succesfully");
        this.$emit("refresh", this.repositoryId);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
