<template>
  <FormSection
    :icon="isActive ? 'mdi-account' : 'mdi-account-outline'"
    :is-active="isActive"
    class="assignRule"
    v-on="$listeners"
  >
    <template #title>Apply for users & groups</template>

    <Security
      v-model="rules"
      :repository-id="repositoryId"
      :mode="modes"
      :file-security="editSecurity"
      :columns="fields"
      @refresh="handleRefresh"
      @reset="reset"
    />

    <!-- fields -->

    <div id="item-list">
      <div class="row">
        <div class="col-12 q-mb-md">
          <div class="row" style="float: right">
            <ExpandableSearch class="q-mr-sm col-auto" @search="searchRow" />

            <BaseActionButton
              v-tooltip.top="'refresh'"
              is-flat
              no-border
              icon="eva-refresh"
              class="col-auto"
              @click="handleRefresh"
            />

            <BaseButton
              icon="eva-plus"
              label="Add Rules/Conditions"
              class="q-ml-md col-auto"
              @click="add()"
            />
          </div>
        </div>

        <template v-if="securities.length">
          <div class="col-12">
            <BaseScrollbar class="q-pb-sm" style="max-height: 320px">
              <table>
                <thead>
                  <tr>
                    <th class="action"></th>
                    <th>Rule Name</th>
                    <th>Visibility</th>
                    <th>users</th>
                    <th>groups</th>
                    <th class="action"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="field in securities" :key="field.id">
                    <td style="width: 5px">
                      <BaseActionButton
                        v-tooltip:secondary.left="'edit rule'"
                        is-flat
                        color="secondary"
                        icon="eva-edit-outline"
                        no-border
                        @click="edit(field.id)"
                      />
                    </td>
                    <td>{{ field.name }}</td>
                    <td style="width: 5px">{{ field.visibility }}</td>
                    <td style="width: 5px">{{ field.userId_Array.length }}</td>
                    <td style="width: 5px">{{ field.groupId_Array.length }}</td>
                    <td style="width: 5px">
                      <BaseActionButton
                        v-tooltip:red.right="'delete rule'"
                        is-flat
                        color="red"
                        icon="eva-trash-2-outline"
                        no-border
                        @click="deleteDocumentSecurity(field.id)"
                      />
                      <!-- <div class="row"></div> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </BaseScrollbar>
          </div>
        </template>

        <template v-else>
          <div class="q-ma-xl text-bold">
            No rules were found to protect your folder or document. Please add a
            rule to ensure the security of your content.
          </div>
        </template>
      </div>
    </div>

    <!-- ... -->
  </FormSection>
</template>

<script>
import FormSection from "@/components/common/item-builder/FormSection.vue";
import Security from "@/components/common/display/item-actions/components/Security.vue";
import ExpandableSearch from "@/components/common/ExpandableSearch.vue";

import { repository } from "@/api/factory.js";
export default {
  name: "RulesAndConditions",

  components: {
    FormSection,
    Security,
    ExpandableSearch,
  },

  props: {
    repositoryId: {
      type: String,
      default: "",
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    mode: {
      type: String,
      default: "NEW",
    },

    fields: {
      type: Array,
      required: true,
    },

    rule: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      rules: false,
      securities: [],
      editSecurity: {},
      modes: "",
      securityData: [],
    };
  },

  watch: {
    rule: {
      immediate: true,
      deep: true,
      handler() {
        this.securities = [];
        if (this.rule.length) {
          this.securities = this.rule;
        }
      },
    },
  },

  mounted() {},

  methods: {
    handleRefresh(id) {
      this.$emit("refresh", id);
    },

    reset() {
      this.editSecurity = {};
    },

    add() {
      this.rules = true;
      this.modes = "ADD";
    },

    searchRow(search) {
      this.securityData = this.rule;
      if (search) {
        this.securities = this.securityData.filter((item) => {
          return item.name.toLowerCase().includes(search.toLowerCase());
        });
      } else {
        this.securities = this.securityData;
      }
    },

    edit(id) {
      this.editSecurity = this.securities.find(
        (security) => security.id === id
      );
      this.editSecurity.filterBy.forEach((group) => {
        if (group.filters.length) {
          group.filters.forEach((row) => {
            if (row.dataType === "SINGLE_SELECT") {
              console.log(row.value);
              try {
                row.arrayValue = JSON.parse(row.value);
              } catch (e) {
                row.arrayValue = [];
                row.arrayValue.push(row.value);
              }
            }
          });
        }
      });
      this.editSecurity;
      this.rules = true;
      this.modes = "EDIT";
    },

    async deleteDocumentSecurity(id) {
      let payload = { isDeleted: true };
      const { error } = await repository.updateDocumentSecurity(id, payload);

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.$alert.success("Document security updated succesfully");
      this.handleRefresh(this.repositoryId);
    },
  },
};
</script>

<style lang="scss" scoped>
#item-list {
  table {
    table-layout: fixed;
    max-width: 100%;
    min-width: calc(100% - 1px);
    border-collapse: collapse;
    white-space: nowrap;

    thead {
      tr {
        height: 36px;

        th {
          @extend .text-xs;
          padding: 8px 8px 16px;
          text-align: left;
          font-weight: normal;
        }
      }
    }

    tbody {
      tr {
        border-top: 1px solid var(--divider-color);

        td {
          padding: 8px;
          height: 52px;
          text-align: left;
        }
      }
    }
  }
}
</style>

<style lang="scss">
#form-section.assignRule {
  #form {
    width: 40vw !important;
  }
}
</style>
