<template>
  <Sheet
    :value="value"
    width="calc(100vw - 220px)"
    has-footer
    @input="closeSheet"
  >
    <!-- title -->

    <template #title>{{ name }} user security</template>

    <!-- ... -->

    <!-- field details -->

    <template #default>
      <div class="q-pa-md">
        <!-- user -->

        <UserTable
          :list="usersList"
          :add-field.sync="security"
          :edit-field="editField"
          :action="action"
          :text="text"
        />

        <!-- ... -->
      </div>
    </template>

    <!-- ... -->

    <!-- footer -->

    <template #footer>
      <BaseButton
        is-flat
        label="cancel"
        class="q-mr-sm"
        @click="closeSheet(false)"
      />

      <BaseButton
        :label="label"
        @click="mode === 'ADD' ? addField() : saveField()"
      />
    </template>

    <!-- ... -->
  </Sheet>
</template>

<script>
import Sheet from "@/components/common/popup/Sheet.vue";
import UserTable from "./userTable/UserTable.vue";

import { repository } from "@/api/factory.js";

export default {
  name: "UserSheet",

  components: {
    Sheet,
    UserTable,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    editField: {
      type: Object,
      default: () => {},
    },

    mode: {
      type: String,
      required: true,
    },

    repositoryId: {
      type: String,
      default: "",
    },

    user: {
      type: Array,
      default: () => [],
    },

    users: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      name: "",
      security: [],
      usersList: [],
      action: false,
      text: "",
    };
  },

  computed: {
    label() {
      if (this.mode === "ADD") {
        return "Add";
      } else return "Save";
    },
  },

  watch: {
    users: {
      immediate: true,
      deep: true,
      handler() {
        this.filterUsers();
      },
    },

    editField: {
      immediate: true,
      deep: true,
      handler() {
        this.filterUsers();
      },
    },

    mode: {
      immediate: true,
      handler() {
        if (this.mode === "ADD") {
          this.name = "Add";
        } else if (this.mode === "EDIT") {
          this.name = "Edit";
        }
      },
    },
  },

  mounted() {},

  methods: {
    reset() {
      this.security = [];
      this.action = false;
      this.text = "";
    },

    closeSheet() {
      this.$emit("input", false);
      this.$emit("reset");
      this.reset();
    },

    filterUsers() {
      if (Object.keys(this.editField).length !== 0) {
        const userId = this.editField.userId;

        const matchedUser = this.users.filter((user) => userId === user.value);

        this.usersList =
          matchedUser &&
          matchedUser.map((user) => ({
            id: this.$nano.id(),
            label: user.label,
            value: user.value,
          }));
      } else if (this.user.length) {
        const userIds = this.user.map((u) => u.userId);

        // Filter out payload items that match the 'group' array by ID
        const unmatchedUsers = this.users.filter(
          (user) => !userIds.includes(user.id)
        );

        this.usersList =
          unmatchedUsers &&
          unmatchedUsers.map((user) => ({
            id: this.$nano.id(),
            label: user.label,
            value: user.value,
          }));
      } else {
        this.usersList = this.users;
      }
    },

    async addField() {
      const userIds = this.security.map((item) => item.userId);

      const options = this.security.map((item) => (item.options.view ? 0 : 1));

      if (userIds.includes(0) && options.includes(1)) {
        this.action = true;
        this.text =
          "Don't leave user column blank and don't remove view from OPTIONS";
      } else if (userIds.includes(0)) {
        this.action = true;
        this.text = "Select any of the user, don't leave user column empty";
      } else if (options.includes(1)) {
        this.btn = true;
        this.text = "Don't remove view from options column";
      } else {
        const updatedSecurity = this.security.map((item) => {
          //       // Create a copy of the item without the accessibilities property and id
          const newItem = { ...item };
          delete newItem.id;
          delete newItem.accessibilities;
          return newItem;
        });
        const payload = {
          repositoryId: this.repositoryId,
          userOptions: updatedSecurity,
          groupOptions: [],
        };

        const { error } = await repository.insertRepositorySecurity(payload);

        if (error) {
          this.$alert.error(error);
          return;
        }

        this.closeSheet();
        this.$alert.success("New folder security added succesfully");
        this.$emit("refresh", this.repositoryId);
      }
    },

    async saveField() {
      const userIds = this.security.map((item) => item.userId);

      const options = this.security.map((item) => (item.options.view ? 0 : 1));

      if (userIds.includes(0) && options.includes(1)) {
        this.action = true;
        this.text =
          "Don't leave USER colum blank and don't remove view from OPTIONS";
      } else if (userIds.includes(0)) {
        this.action = true;
        this.text = "Select any of the user, don't leave USER column empty";
      } else if (options.includes(1)) {
        this.btn = true;
        this.text = "Don't remove view from OPTIONS column";
      } else {
        const updatedSecurity = this.security.map((item) => {
          //       // Create a copy of the item without the accessibilities property and id
          const newItem = { ...item };
          delete newItem.accessibilities;
          return newItem;
        });
        const id = this.repositoryId;
        const payload = {
          repositoryId: this.repositoryId,
          userOptions: updatedSecurity,
          groupOptions: [],
        };

        const { error } = await repository.updateRepositorySecurity(
          id,
          payload
        );

        if (error) {
          this.$alert.error(error);
          return;
        }

        this.closeSheet();
        this.$alert.success("Folder security updated succesfully");
        this.$emit("refresh", this.repositoryId);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
